import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { Config } from "@synisys/idm-ui-shell/app/config";
import 'zone.js/dist/zone';
// /** IE9, IE10 and IE11 requires following polyfills. **/
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';
if (process.env.NODE_ENV === 'production') {
    // Production
    enableProdMode();
}
else {
    // Development and test
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}
// EnableProdMode();
platformBrowserDynamic([
    { provide: 'imagesPath', useValue: 'images' },
    { provide: 'configPath', useValue: './app/config' },
    { provide: Config.coreConfigPathToken, useValue: './app/config' }
]).bootstrapModule(AppModule, [{ defaultEncapsulation: ViewEncapsulation.None }]);
