var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Injectable } from '@angular/core';
var ProxyReportingServiceInterceptor = /** @class */ (function () {
    function ProxyReportingServiceInterceptor() {
    }
    ProxyReportingServiceInterceptor_1 = ProxyReportingServiceInterceptor;
    ProxyReportingServiceInterceptor.prototype.intercept = function (req, next) {
        if (ProxyReportingServiceInterceptor_1.isCompatibleRequest(req.url)) {
            var pageId = window.location.href.split("/").pop();
            var totalCountRequest = ProxyReportingServiceInterceptor_1.constructTotalCountRequestOrNull(+pageId, req);
            if (totalCountRequest)
                return next.handle(totalCountRequest);
            var pageType = ProxyReportingServiceInterceptor_1.PAGES_TYPES.get(+pageId);
            if (!pageType)
                return next.handle(req);
            var url = req.url.includes('reportingservice')
                ? req.url.replace('reportingservice', 'ugacmsservice')
                : req.url.replace('reportexportservice', 'ugacmsservice');
            var newReq = req.clone({
                url: url + "?type=" + pageType
            });
            return next.handle(newReq);
        }
        return next.handle(req);
    };
    ProxyReportingServiceInterceptor.isCompatibleRequest = function (url) {
        var targetUrls = ['/reportingservice/v1/uga-cms/execute-report', '/reportexportservice/v1/uga-cms/export-report'];
        return targetUrls.some(function (targetUrl) { return url.endsWith(targetUrl); }) && /\/pages\/\d+$/.test(window.location.href);
    };
    ProxyReportingServiceInterceptor.constructTotalCountRequestOrNull = function (pageId, req) {
        if (!ProxyReportingServiceInterceptor_1.DASHBOARD_PAGE_IDS.includes(pageId))
            return null;
        var type = ProxyReportingServiceInterceptor_1.detectTypeOrNull(req);
        if (!type)
            return null;
        var url = req.url.replace('reportingservice', 'ugacmsservice');
        return req.clone({
            url: url + "?type=" + type + "&onlyTotalCount=true"
        });
    };
    ProxyReportingServiceInterceptor.detectTypeOrNull = function (req) {
        var body = req.body;
        if (body.startsWith('report=%7B%22columnRootDto%22:%7B%22type%22:%22ColumnRootElement%22,%22children%22:%5B%7B%22orderType%22:0,%22filterTreeDto%22:null,%22showOnRowsTotal%22:true,%22children%22:%5B%5D,%22type%22:%22ColumnMeasureElement%22,%22userDefinedAggregationType%22:null,%22isShowInPercentage%22:false,%22measurePathHolderId%22:1543,%22currencyId%22:null%7D%5D%7D,%22rowRootDto%22:%7B%22children%22:%5B%5D%7D,%22tableGrouping%22:%7B%22elements%22:%5B%5D%7D%7D')) {
            return 'REGISTERED';
        }
        else if (body.startsWith('report=%7B%22columnRootDto%22:%7B%22type%22:%22ColumnRootElement%22,%22children%22:%5B%7B%22orderType%22:0,%22filterTreeDto%22:null,%22showOnRowsTotal%22:true,%22children%22:%5B%5D,%22type%22:%22ColumnMeasureElement%22,%22userDefinedAggregationType%22:null,%22isShowInPercentage%22:false,%22measurePathHolderId%22:1001,%22currencyId%22:null%7D%5D%7D,%22rowRootDto%22:%7B%22children%22:%5B%5D%7D,%22tableGrouping%22:%7B%22elements%22:%5B%5D%7D%7D')) {
            return 'BACKLOG';
        }
        else if (body.startsWith('report=%7B%22columnRootDto%22:%7B%22type%22:%22ColumnRootElement%22,%22children%22:%5B%7B%22orderType%22:0,%22filterTreeDto%22:null,%22showOnRowsTotal%22:true,%22children%22:%5B%5D,%22type%22:%22ColumnMeasureElement%22,%22userDefinedAggregationType%22:null,%22isShowInPercentage%22:false,%22measurePathHolderId%22:1563,%22currencyId%22:null%7D%5D%7D,%22rowRootDto%22:%7B%22children%22:%5B%5D%7D,%22tableGrouping%22:%7B%22elements%22:%5B%5D%7D%7D')) {
            return 'PENDING';
        }
        else if (body.startsWith('report=%7B%22columnRootDto%22:%7B%22type%22:%22ColumnRootElement%22,%22children%22:%5B%7B%22orderType%22:0,%22filterTreeDto%22:null,%22showOnRowsTotal%22:true,%22children%22:%5B%5D,%22type%22:%22ColumnMeasureElement%22,%22userDefinedAggregationType%22:null,%22isShowInPercentage%22:false,%22measurePathHolderId%22:1546,%22currencyId%22:null%7D%5D%7D,%22rowRootDto%22:%7B%22children%22:%5B%5D%7D,%22tableGrouping%22:%7B%22elements%22:%5B%5D%7D%7D')) {
            return 'CLOSED';
        }
        return null;
    };
    var ProxyReportingServiceInterceptor_1;
    ProxyReportingServiceInterceptor.PAGES_TYPES = new Map([
        [1111, 'PENDING'],
        [2222, 'BACKLOG'],
        [3333, 'REGISTERED'],
        [4444, 'CLOSED'],
        [1110, 'PENDING'],
        [2220, 'BACKLOG'],
        [3330, 'REGISTERED'],
        [4440, 'CLOSED']
    ]);
    ProxyReportingServiceInterceptor.DASHBOARD_PAGE_IDS = [5555, 7777];
    ProxyReportingServiceInterceptor = ProxyReportingServiceInterceptor_1 = __decorate([
        Injectable()
    ], ProxyReportingServiceInterceptor);
    return ProxyReportingServiceInterceptor;
}());
export { ProxyReportingServiceInterceptor };
