var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrentLanguageProvider, SessionDataProvider } from '@synisys/idm-session-data-provider-api-js';
import { CurrentLanguageManager, LoginGuard, MainOnlineProvidersModule, SessionDataProviderImpl, UserSessionIdentityIntercepter } from '@synisys/idm-ui-shell';
import { LoginPagePreventionGuard } from '@synisys/idm-ui-shell/app/services/login-page-prevention-guard';
import { HttpUserService, UserService } from '@synisys/idm-um-client-js';
import { AppComponent } from './app.component';
import { appRoute } from './app.routing';
import { AsyncLocalStorageModule } from "angular-async-local-storage";
import { UnauthorizedInterceptor } from "@synisys/idm-authentication-client-js";
import { AuthTokenInterceptor } from "@synisys/idm-authentication-client-js/src";
import { WelcomePageUrlResolver } from "./service/welcome-page-url.resolver";
import { CachingInterceptor } from "@synisys/ejustice-controls";
import { ProxyReportingServiceInterceptor } from "./service/proxy-reporting-service.interceptor";
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule = __decorate([
        NgModule({
            imports: [
                /*sss*/
                BrowserAnimationsModule,
                HttpClientModule,
                appRoute,
                MainOnlineProvidersModule,
                AsyncLocalStorageModule
            ],
            declarations: [
                AppComponent,
            ],
            providers: [
                LoginPagePreventionGuard,
                LoginGuard,
                WelcomePageUrlResolver,
                SessionDataProviderImpl,
                CurrentLanguageManager,
                { provide: UserService, useClass: HttpUserService },
                { provide: CurrentLanguageProvider, useExisting: SessionDataProviderImpl },
                { provide: SessionDataProvider, useExisting: SessionDataProviderImpl },
                { provide: HTTP_INTERCEPTORS, useExisting: UnauthorizedInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useExisting: AuthTokenInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: ProxyReportingServiceInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useExisting: UserSessionIdentityIntercepter, multi: true },
            ],
            bootstrap: [AppComponent],
        })
    ], AppModule);
    return AppModule;
}());
export { AppModule };
