import { RouterModule } from '@angular/router';
import { LoginGuard } from '@synisys/idm-ui-shell';
import { LoginPagePreventionGuard } from '@synisys/idm-ui-shell/app/services/login-page-prevention-guard';
export var appRouting = [
    {
        path: 'login', canActivate: [LoginPagePreventionGuard],
        loadChildren: function () { return new Promise(function (resolve, reject) { require.ensure([], function (require) { resolve(require('./login/login.module')['LoginModule']); }, function (e) { reject({ loadChunkError: true, details: e }); }, 'LoginModule'); }); },
    },
    {
        path: 'registration',
        loadChildren: function () { return new Promise(function (resolve, reject) { require.ensure([], function (require) { resolve(require('./registration/registration.module')['RegistrationModule']); }, function (e) { reject({ loadChunkError: true, details: e }); }, 'RegistrationModule'); }); }
    },
    {
        path: 'resetpassword',
        loadChildren: function () { return new Promise(function (resolve, reject) { require.ensure([], function (require) { resolve(require('@synisys/idm-user-data-ui/app/resetPassword/reset-password.module')['ResetPasswordModule']); }, function (e) { reject({ loadChunkError: true, details: e }); }, 'ResetPasswordModule'); }); },
    },
    {
        path: 'expiredpassword',
        loadChildren: function () { return new Promise(function (resolve, reject) { require.ensure([], function (require) { resolve(require('@synisys/idm-user-data-ui/app/expired-password/expired-password.module')['ExpiredPasswordModule']); }, function (e) { reject({ loadChunkError: true, details: e }); }, 'ExpiredPasswordModule'); }); },
    },
    {
        path: 'ssoprelogin',
        loadChildren: function () { return new Promise(function (resolve, reject) { require.ensure([], function (require) { resolve(require('@synisys/idm-ui-shell/app/modules/sso-pre-login/sso-pre-login.module')['SSOPreLoginModule']); }, function (e) { reject({ loadChunkError: true, details: e }); }); }); },
    },
    {
        path: '',
        canLoad: [LoginGuard],
        loadChildren: function () { return new Promise(function (resolve, reject) { require.ensure([], function (require) { resolve(require('./shell/main.shell.module')['MainShellModule']); }, function (e) { reject({ loadChunkError: true, details: e }); }, 'MainShellModule'); }); },
    },
];
export var appRoute = RouterModule.forRoot(appRouting);
