var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, ViewEncapsulation } from '@angular/core';
import { Subject } from "rxjs";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ApplicationPropertiesService } from "@synisys/idm-application-properties-service-client-js";
import { LoginService } from "@synisys/idm-ui-shell/app/services/login.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(router, loginService, applicationPropertiesService) {
        this.router = router;
        this.loginService = loginService;
        this.applicationPropertiesService = applicationPropertiesService;
        this._destroy$ = new Subject();
    }
    AppComponent.prototype.ngOnDestroy = function () {
        this._destroy$.next();
        this._destroy$.complete();
    };
    AppComponent.prototype.ngOnInit = function () {
        this.configureGA();
        this.processingUnderMaintenance();
    };
    AppComponent.prototype.configureGA = function () {
        var _this = this;
        this.applicationPropertiesService.loadProjectConfigs().then(function (configs) {
            if (configs.hasOwnProperty('ga') && configs['ga']) {
                _this.addGAScript(configs['ga']);
                _this.router.events
                    .pipe(takeUntil(_this._destroy$))
                    .subscribe(function (event) {
                    if (event instanceof NavigationEnd) {
                        gtag('event', 'page_view', {
                            page_path: event.urlAfterRedirects
                        });
                    }
                });
            }
        });
    };
    AppComponent.prototype.processingUnderMaintenance = function () {
        var _this = this;
        this.router.events
            .subscribe(function (event) {
            var navigationStartBesideLogin = event instanceof NavigationStart && !event.url.endsWith('/login');
            if (navigationStartBesideLogin) {
                _this.applicationPropertiesService.loadProjectConfigs().then(function (configs) {
                    if (configs.hasOwnProperty('under-maintenance') && configs['under-maintenance'] === 'true') {
                        _this.loginService.logout();
                        _this.router.navigate(['/login']);
                    }
                });
            }
        });
    };
    AppComponent.prototype.addGAScript = function (gaTrackingId) {
        var gtagScript = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;
        document.head.appendChild(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', gaTrackingId, { send_page_view: false });
    };
    var _a, _b, _c;
    AppComponent = __decorate([
        Component({
            selector: 'sis-app',
            template: require('./app.component.html'),
            encapsulation: ViewEncapsulation.None
        }),
        __metadata("design:paramtypes", [typeof (_a = typeof Router !== "undefined" && Router) === "function" && _a || Object, typeof (_b = typeof LoginService !== "undefined" && LoginService) === "function" && _b || Object, typeof (_c = typeof ApplicationPropertiesService !== "undefined" && ApplicationPropertiesService) === "function" && _c || Object])
    ], AppComponent);
    return AppComponent;
}());
export { AppComponent };
