var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@synisys/idm-authentication-client-js';
import { Access, AuthorizationService } from '@synisys/idm-authorization-client-js';
import { ApplicationPropertiesService } from '@synisys/idm-application-properties-service-client-js';
var WelcomePageUrlResolver = /** @class */ (function () {
    function WelcomePageUrlResolver(router, applicationPropertiesService, authenticationService, authorizationService) {
        this.router = router;
        this.applicationPropertiesService = applicationPropertiesService;
        this.authenticationService = authenticationService;
        this.authorizationService = authorizationService;
    }
    WelcomePageUrlResolver.prototype.canActivate = function (route, state) {
        var _this = this;
        this.applicationPropertiesService.loadProjectConfigs().then(function (configs) {
            _this.hasAccess(route.data['permissionKey']).subscribe(function (hasAccess) {
                var defaultWelcomePageUrl = configs.hasOwnProperty('defaultWelcomePageUrl') &&
                    configs['defaultWelcomePageUrl'];
                if (hasAccess) {
                    defaultWelcomePageUrl = configs.hasOwnProperty('defaultWelcomeDashboardPageUrl') &&
                        configs['defaultWelcomeDashboardPageUrl'];
                }
                _this.router.navigate([defaultWelcomePageUrl]);
            });
        });
        return false;
    };
    WelcomePageUrlResolver.prototype.hasAccess = function (permissionKeys) {
        var _this = this;
        return this.authenticationService.getUserData().flatMap(function (userData) {
            var accessList = permissionKeys.map(function (permissionKey) { return _this.authorizationService.getPermissionAccessValue(userData.userId, permissionKey)
                .map(function (perm) { return perm.access === Access.FULL; }); });
            if (accessList.length === 0) {
                return Observable.of(true);
            }
            return Observable.combineLatest(accessList)
                .map(function (accessList) {
                return accessList.some(function (value) { return value === true; });
            });
        });
    };
    var _a, _b, _c, _d;
    WelcomePageUrlResolver = __decorate([
        Injectable(),
        __metadata("design:paramtypes", [typeof (_a = typeof Router !== "undefined" && Router) === "function" && _a || Object, typeof (_b = typeof ApplicationPropertiesService !== "undefined" && ApplicationPropertiesService) === "function" && _b || Object, typeof (_c = typeof AuthenticationService !== "undefined" && AuthenticationService) === "function" && _c || Object, typeof (_d = typeof AuthorizationService !== "undefined" && AuthorizationService) === "function" && _d || Object])
    ], WelcomePageUrlResolver);
    return WelcomePageUrlResolver;
}());
export { WelcomePageUrlResolver };
